import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import RelatedPosts from '../components/RelatedPosts'
import TagList from "../components/TagList"

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  date,
  featuredpost,
  featuredimage,
  helmet,
  tableOfContents,
}) => {
  const PostContent = contentComponent || Content

  return (
    <div>
      <div className="l-container">
        {helmet || ''}
        <p className="c-pageTitle" data-spacingsp="mb7">
          <span className="c-pageTitle__container">
            <span className="c-pageTitle__textJa">ブログ</span>
            <span className="c-pageTitle__textEn">BLOG</span>
          </span>
        </p>
        <div className="l-container--wide">
          <ul className="p-breadCrumbs">
            <li className="p-breadCrumbs__item"><Link to="/" className="p-breadCrumbs__link">ホーム</Link></li>
            <li className="p-breadCrumbs__item isCurrent"><Link to="/blog" className="p-breadCrumbs__link">ブログ</Link></li>
          </ul>
        </div>
        <section className="p-blogPost">
          <header className="p-blogPost__header">
            <h1 className="p-blogPost__title">
              {title}
            </h1>
            <p className="p-blogPost__date">&#091;{date}&#093;</p>
            {tags && tags.length ? (
              <ul className="p-blogPost__tagList">
                {tags.map(tag => (
                  <li key={tag + `tag`} className="c-tagButton p-blogPost__tag">
                    <Link to={`/tags/${kebabCase(tag)}/`}  className="c-tagButton__link p-blogPost__tagLink">{tag}</Link>
                  </li>
                ))}
              </ul>
            ) : null}

          </header>
          {featuredimage ? (
            <figure className={`p-blogPost__figure ${featuredpost  ? ( 'c-featured') : null }`}>
              <PreviewCompatibleImage
                imageInfo={{
                  image: featuredimage,
                  alt: `featured image thumbnail for post ${title}`,
                }}
                />
            </figure>
          ) : (
            <figure className="p-blogPost__figure--noThumbnail">
              <img className="p-blogPost__img" src="/img/blog/nothumbnail.png" alt="no thumbnail" />
            </figure>
            )}
          <article className="p-blogPost__box--description">
            <h2 className="p-blogPost__boxTitle">DESCRIPTION</h2>
            {description}
          </article>
          {tableOfContents ? (
            <div
              className="p-blogPost__box--index"
              dangerouslySetInnerHTML={{ __html: tableOfContents }}
            />
          ) : null}
          <div className="p-blogPost__body">
            <PostContent content={content} />
          </div>

          <footer className="p-blogPost__footer">
            <p className="p-blogPost__date">&#091;{date}&#093;</p>
            {tags && tags.length ? (
              <ul className="p-blogPost__taglist">
                {tags.map(tag => (
                  <li key={tag + `tag`} className="c-tagButton p-blogPost__tag">
                    <Link to={`/tags/${kebabCase(tag)}/`}  className="c-tagButton__link p-blogPost__tagLink">{tag}</Link>
                  </li>
                ))}
              </ul>
            ) : null}
          </footer>
        </section>
      </div>
    </div>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  date: PropTypes.string,
  featuredimage: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data
  let schemaOrgJSONLD = [
    {
      '@context': 'https://schema.org',
      '@type': 'Corporation',
      '@id': "https://arbor.jp",
      url: "https://arbor.jp",
      name: "株式会社アーバ",
      alternateName: "株式会社アーバ",
      address: {
        '@type': 'PostalAddress',
        'postalCode': '372-0012',
        'addressCountry': 'JP',
        'addressRegion': '群馬県',
        'addressLocality': '伊勢崎市',
        'streetAddress': '豊城町2144−3',
      },
      founder: {
        '@type': 'Person',
        'givenName': '直人',
        'familyName': '石丸',
      },
      foundingDate: '2018-1-11',
      description: post.frontmatter.description,
    },
    {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          name: "トップページ",
          position: 1,
          item: {
            "@id": "https://arbor.jp",
            "name": "株式会社アーバ",
          }
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            "@id": typeof window !== "undefined" && window.location.href,
            "name": post.frontmatter.title,
          }
        },
      ],
    },
  ];
  
  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        tableOfContents={post.tableOfContents}
        featuredimage={post.frontmatter.featuredimage}
        featuredpost={post.frontmatter.featuredpost}
      />
      <div className="u-bg--ultraLightGray" data-spacing="pv8">
        <h2
          className="u-font--medium u-font--36 u-alignCenter u-font--sp24"
          data-spacing="pt6 mb8"
          data-spacingsp="pt0 mb0"
        >
          関連記事
        </h2>
        <div className="l-container">
          <RelatedPosts
            tags={post.frontmatter.tags}
          />
        </div>
      </div>
      <aside className="p-tagList" data-spacing="mb0">
        <div className="l-container">
          <h3 className="u-alignCenter u-font--20 u-font--termina u-font--medium" data-spacing="mb1">TAGS</h3>
          <TagList />
        </div>
      </aside>
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      tableOfContents(
        absolute: false
        pathToSlugField: "frontmatter.path"
        maxDepth: 3
      )
      frontmatter {
        date(formatString: "YYYY.M.D")
        path
        title
        description
        tags
        featuredpost
        featuredimage{
          childImageSharp {
            fluid(maxWidth: 670, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
