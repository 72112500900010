import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import { kebabCase } from 'lodash'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const RelatedPosts = ({ title, tags }) => (
  <StaticQuery
    query={graphql`
      query RelatedPostsQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              excerpt(format: PLAIN, pruneLength: 75, truncate: true)
              frontmatter {
                title
                tags
                date(formatString: "YYYY/MM/DD")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 335, maxHeight: 223, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const relatedPosts = data.allMarkdownRemark.edges.filter(edge => {
        if (edge.node.frontmatter.title === title) {
          return false;
        }
        for (let i = 0; i < 10; i++) {
          if (edge.node.frontmatter.tags.indexOf(tags[i]) >= 0) {
            return true;
          }
        }
        return false;
      });
      if (!relatedPosts) {
        return null;
      }
      const relatedPost3 = relatedPosts.slice(0, 3);

      return (
        <div className="l-container">
          <div className="p-blogList">
            {relatedPost3 &&
              relatedPost3.map(({ node: post }) => (
              <div className="p-blogList__item" key={post.id}>
                <article className={`p-blogList__container ${post.frontmatter.featuredpost  ? ( 'c-featured') : null }`}>
                  <Link to={post.fields.slug}>
                    {post.frontmatter.featuredimage ? (
                      <figure className="p-blogList__figure">
                        <PreviewCompatibleImage
                          imageInfo={{
                            image: post.frontmatter.featuredimage,
                            alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          }}
                        />
                      </figure>
                    ) : (
                      <figure className="p-blogList__figure--noThumbnail">
                        <img className="p-blogList__img" src="/img/blog/nothumbnail.png" alt="no thumbnail" />
                      </figure>
                    )}
                  </Link>
                  <div className="p-blogList__body">
                    <Link className="p-blogList__title" to={post.fields.slug}>
                        {post.frontmatter.title}
                      {/* <span className="p-blogList__date">
                        {post.frontmatter.date}
                      </span> */}
                    </Link>
                    {post.frontmatter.tags && post.frontmatter.tags.length ? (
                      <ul className="p-blogList__tags">
                        {post.frontmatter.tags.map(tag => (
                          <li className="c-tagButton p-blogList__tag" key={tag + `tag`}>
                            <Link className="c-tagButton__link" to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                          </li>
                        ))}
                      </ul>
                    ) : null}
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
      );
    }}
  />
);

export default RelatedPosts;

RelatedPosts.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};